import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { HttpRequestMethods, HTTPStatusCodes } from "./Constants";
import { useDispatch } from "react-redux";
import { ActionTypes } from "./Redux/Constants/action-types";
import { ClearStorage, GetStorageItem, SetStorage } from "./Helpers/Authentication";
import { AppConfigConstant } from "./AppConfig";
import AuthMessage from "./AuthMessage";
import { Blank } from "./Constants";
import { useState } from "react";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { userId } = useParams();
  if (!userId) {
    userId = GetStorageItem(AppConfigConstant.IDENTITY);
  }

  const[errorMessage, setErrorMessage] = useState(Blank);

  useEffect(() => {
    /*Validate Login Ticket */
    const url: string = `${AppConfigConstant.API_URL}ValidateUserAppAccess`;
    const requestOptions = { userId: userId, AppCode: AppConfigConstant.APP_CODE };
    fetch(url, {
      method: HttpRequestMethods.POST,
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestOptions)
    }).then((response) => {
      if (response.status === HTTPStatusCodes.OK) {
        response.json().then((json) => {
          if (json.StatusCode === HTTPStatusCodes.OK) {
            SetStorage(AppConfigConstant.HASH, json.ResponseMessage);
            SetStorage(AppConfigConstant.IDENTITY, userId as string);
            SetStorage(AppConfigConstant.USER_INSTANCE, json.UserData.Instance);
            SetStorage(AppConfigConstant.API_VERSION, Blank);
            SetStorage(AppConfigConstant.AVAI_INSTANCES, JSON.stringify(json.UserData.AvailableInstances));
            dispatch({ type: ActionTypes.SET_LOGIN, payload: { loginStatus: true, id: userId, authTicket: json.ResponseMessage, instance: GetStorageItem(AppConfigConstant.USER_INSTANCE), showInstanceSelector: false } });
            navigate("/Programs/Add");
          }
          else if(json.StatusCode === HTTPStatusCodes.InternalServerError) {
            setErrorMessage(json.ResponseMessage);
          } else { navigate("/Home"); }
        })
      } else {
        ClearStorage();
        window.location.href = AppConfigConstant.LOGIN_URL;
      }
    }).catch((error) => {
      ClearStorage();
      setErrorMessage(JSON.stringify(error));
    });
    return () => { };
  }, [userId, navigate, dispatch]);

  return (
    <AuthMessage error={errorMessage} />
  );

};

export default Auth;

