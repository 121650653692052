import { API_URL, Blank, ErrorMessages, HttpRequestMethods, HTTPStatusCodes } from "../Constants";

interface IFetch {
  error: boolean;
  statusCode: number,
  errorMessage: string;
  data: any
};

interface IRequestParams {
  method: string,
  credentials: RequestCredentials,
  headers: {},
  body? : string
}

export async function getData(requestOptions: any, httpMethod: string, urlString: string = null): Promise<IFetch> {
  const requestParams: IRequestParams = {
    method: httpMethod,
    credentials: "same-origin",
    headers: { "Content-Type": "application/json"},
    body: JSON.stringify(requestOptions)
  };
  if(httpMethod === HttpRequestMethods.GET){
    delete requestParams['body'];
  }
  const response = await fetch(urlString === null ? API_URL : urlString, requestParams);
  if (response.status === HTTPStatusCodes.OK) {
    const data = await response.json();
    return { 
      error: false, 
      statusCode: HTTPStatusCodes.OK, 
      errorMessage: Blank, 
      data: data 
    };
  };

  return { 
    error: true, 
    statusCode: HTTPStatusCodes.InternalServerError, 
    errorMessage: ErrorMessages.NetworkError, 
    data: Blank 
  };
};

export async function postData(requestOptions: any, urlString: string = null): Promise<IFetch> {
  return getData(requestOptions, HttpRequestMethods.POST, urlString);
};