import { Form, Table, Accordion, Row, Col } from "react-bootstrap";
import { AIMTableRowHeader } from "../../Controls/AIMTableControls";
import { Blank } from "../../Constants";
import { BrowserView, MobileView } from "react-device-detect";
import { AIMFloatingLabelInput } from "../../Controls/AIMInput";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const ProgramActivities = (props: any) => {
  var programDetailInfo = props.programDetailInfo;
  const animatedComponent = makeAnimated();
  const selectStyles = {
    control: (styles: any) => ({ ...styles, minHeight: "55px", boxShadow: "none" }),
  };
  return (
    <>
      {Array.isArray(programDetailInfo) ?
        <>
          <BrowserView>
            <Table striped>
              <AIMTableRowHeader rowHeaders={["Name", "Duration (mins)", "Participant", "Remark", "Description"]} />
              <tbody>
                {
                  programDetailInfo.map((activities: any, eventIndex: number) => (
                    activities.entries.map((activity: any, activityIndex: number) => (
                      <tr key={activityIndex}>
                        <td>
                          {
                            activities.multiple === true ? <span onClick={() => props.activityHandler(activities.id, activity, activityIndex, activity.uuid)} className={activities.multiple === true ? "pointer" : Blank} >{activityIndex === 0 ? <span className="icon"><FaPlusCircle className="icon-small add-blue" /></span> : <span className="icon remove-red"><FaMinusCircle className="icon-small" /></span>}{activities.name + (activityIndex > 0 ? Blank : Blank)}</span> : <span className="single-activty">{activities.name}</span>
                          }
                        </td>
                        <td style={{ width: "150px" }}>
                          <Form.Control 
                            type="number" 
                            placeholder="Duration" 
                            name="duration" 
                            value={activity.duration > 0 ? activity.duration : Blank} 
                            onChange={(event: any) => props.valueHandler(activity, event)} 
                          />
                        </td>
                        <td style={{ width: "515px" }}>
                          {
                            activities.multiParticipants ?
                              <Select components={animatedComponent} options={props.MultiSelectOptions} isMulti={true} isSearchable={true} placeholder="Select Participants" onChange={(event) => props.participantHandeler(activity, { name: "participants", value: event }, activities.multiParticipants)} defaultValue={activity.participants} />
                              :
                              <Select options={props.MultiSelectOptions} isMulti={false} isSearchable={true} isClearable={true} placeholder="Select Participant" onChange={(event) => props.participantHandeler(activity, { name: "participants", value: event?.value }, activities.multiParticipants)} defaultValue={activity.participants[0]} />
                          }
                        </td>
                        <td style={{ width: "175px" }}>
                          <Select 
                            name="remark" 
                            options={activity.remarks} 
                            placeholder="Remark" 
                            isClearable={true} 
                            defaultValue={activity.remark[0]} 
                            onChange={(event) => props.remarkHandler(activity, { name: "remark", value: event?.value }, activities.multiParticipants)} 
                          />
                        </td>
                        <td style={{ width: "300px" }}>
                          <Form.Control 
                            type="text" 
                            placeholder="Description" 
                            name="description" 
                            autoComplete="off" 
                            onChange={(event: any) => props.valueHandler(activity, event)} 
                            value={activity.description} 
                          />
                        </td>
                      </tr>
                    ))
                  ))
                }
              </tbody>
            </Table>
            <div className="inst-text" >+Can have multiple rows. Click on activity name to add or remove row</div>
          </BrowserView>
          <MobileView>
            <Accordion>
              {
                programDetailInfo.map((activities: any, eventIndex: number) => (
                  <Accordion.Item eventKey={eventIndex.toString()} key={eventIndex}>
                    <Accordion.Header>{activities.name}</Accordion.Header>
                    <Accordion.Body>
                      {
                        activities.entries.map((activity: any, activityIndex: number) => (
                          <div key={activityIndex}>
                            <Row>
                              <Col xs={12} md={3}>
                                <AIMFloatingLabelInput 
                                  className="mb-3" 
                                  label="Duration" 
                                  type="number" 
                                  name="duration" 
                                  autoComplete="off" 
                                  defaultValue={activity.duration > 0 ? activity.duration : Blank} eventHandler={(event: any) => props.valueHandler(activity, event)} errors={props.errors} 
                                />
                              </Col>
                              <Col xs={12} md={3}>
                                {
                                  activities.multiParticipants ?
                                    <Select 
                                      options={props.MultiSelectOptions} 
                                      isMulti={true} 
                                      isSearchable={true} 
                                      placeholder="Select Participants" 
                                      onChange={(event) => props.participantHandeler(activity, { name: "participants", value: event }, activities.multiParticipants)} 
                                      defaultValue={activity.participants} 
                                      styles={selectStyles} 
                                    />
                                    :
                                    <Select 
                                      options={props.MultiSelectOptions} 
                                      isMulti={false} 
                                      isSearchable={true} 
                                      isClearable={true} 
                                      placeholder="Select Participant" 
                                      onChange={(event) => props.participantHandeler(activity, { name: "participants", value: event?.value }, activities.multiParticipants)} 
                                      defaultValue={activity.participants[0]} 
                                      styles={selectStyles} 
                                    />
                                }
                                <br />
                              </Col>
                              <Col xs={12} md={3}>
                                <Select 
                                  name="remark" 
                                  options={activity.remarks} 
                                  placeholder="Remark" 
                                  isClearable={true} 
                                  styles={selectStyles} 
                                  defaultValue={activity.remark[0]} 
                                  onChange={(event) => props.remarkHandler(activity, { name: "remark", value: event?.value }, activities.multiParticipants)} 
                                />
                                <br />
                              </Col>
                              <Col xs={12} md={3}>
                                <AIMFloatingLabelInput 
                                  className="mb-3" 
                                  label="Description" 
                                  type="text" 
                                  name="description" 
                                  autoComplete="off" 
                                  eventHandler={(event: any) => props.valueHandler(activity, event)} 
                                  defaultValue={activity.description} 
                                  errors={props.errors} 
                                />
                              </Col>
                            </Row>
                            <div className={activities.multiple ? "show" : "hide"}>
                              {
                                activityIndex === 0 ?
                                  <span 
                                    className="iconLinkAdd" onClick={() => props.activityHandler(activities.id, activity, activityIndex, activity.uuid)}>
                                    <FaPlusCircle /> Add Record
                                  </span>
                                  :
                                  <span 
                                    className="iconLinkRemove" 
                                    onClick={() => props.activityHandler(activities.id, activity, activityIndex, activity.uuid)}>
                                    <FaMinusCircle /> Remove Record
                                  </span>
                              }
                            </div>
                            <hr></hr>
                          </div>
                        ))
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              }
            </Accordion>
            <br />
          </MobileView>
        </>
        : Blank}
    </>
  );
};
export default ProgramActivities;