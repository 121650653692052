import { Alert } from "react-bootstrap";
import { ClearStorage } from "./Helpers/Authentication";
import { AppConfigConstant } from "./AppConfig";
import { Button, Modal } from "react-bootstrap";
import { Navbar, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AppName from "./Header/AppName";
import { Instance } from "./Shared";

const AuthMessage = (props: any) => {

  const logOut = () => {
    ClearStorage();
    window.location.href = AppConfigConstant.LOGOUT_URL;
  };

  const goToHome = () => {
    window.location.href = AppConfigConstant.LOGIN_URL;
  };

  if (props.error) {
    return (
      <>
        <Alert variant="danger">
          <Alert.Heading>Internal Server Error: 500</Alert.Heading>
          <p>{props.error}</p>
          <hr />
          <p className="mb-0">
            Please contact <strong><a href="mailto:software@almuntazar.com" style={{ textDecoration: "none", color: "#0d6efd" }}>Software Committee</a></strong>
          </p>
        </Alert>
        <Modal.Footer style={{ borderTop: "none", padding: "0px" }}>
          <Button variant="primary" type="button" onClick={logOut} >Logout</Button>
          <Button variant="primary" type="button" onClick={goToHome} >Home</Button>
        </Modal.Footer>
      </>
    );
  };
  return (
    <>
      <Navbar bg="primary" variant="dark" expand="lg" collapseOnSelect fixed="top" className="navbar-sticky" >
        <Container>
          <Navbar.Brand as={NavLink} to="/Home"><AppName /> <Instance /></Navbar.Brand>
        </Container>
      </Navbar>
      <Container className="pad-top-15px">
        <div style={{ padding: "15px" }}>
          <Alert variant="info">
            <Alert.Heading>Dear user, nice to see you</Alert.Heading>
            <p>
              Please wait, while we validating your application access with server
            </p>
            <hr />
            <p className="mb-0">
              This may take few seconds or a minute depending on your network connection. If you found you dont have access and you need it, please contact your local <strong>TAC Committee</strong>
            </p>
          </Alert>
        </div>
      </Container>
    </>
  );
};

export default AuthMessage;