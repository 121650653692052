import { useEffect, useState, useRef } from "react";
import { Form, Button, Row, Col, FloatingLabel, Alert } from "react-bootstrap";
import { Zones, Users } from "../../Models/Program";
import { AIMFloatingLabelSelect } from "../../Controls/AIMSelect";
import { AIMFloatingLabelInput } from "../../Controls/AIMInput";
import { Blank, HTTPStatusCodes, Messages, MaxImagesUnit } from "../../Constants";
import { AIMDialog, SuccessDialog, AIMLoadingSpinner } from "../../Shared";
import { Actions } from "../../Constants";
import { v4 as uuidv4 } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { AppConfigConstant } from "../../AppConfig";
import { ClearStorage, GetStorageItem, SetStorage } from "../../Helpers/Authentication";
import { HttpRequestMethods } from "../../Constants";
import { useDispatch } from "react-redux";
import { ActionTypes } from "../../Redux/Constants/action-types";
import ProgramActivities from "./ProgramActivities";
import { ModelProperties } from "../../Models/ModelProperties";
import { BrowserView } from "react-device-detect";
import Select from "react-select";
import { Errors } from "../../Models/Program";
import { getData, postData } from "../../Helpers/Fetch";
import IProgram from "../../Models/Interfaces/IProgram";
import { format } from "date-fns";

const Program = () => {
  const inputFile = useRef(null);
  const [programInfo, setProgramInfo] = useState<{ conductionDetail: IProgram }>({
    conductionDetail: {
      id: 0,
      zone: Blank,
      program_type: Blank,
      program_conducted: 1,
      venue: Blank,
      exp_audiance: Blank,
      act_audiance: Blank,
      nazim_report_writer: Blank,
      program_date: Blank,
      booking_amount: Blank,
      molana_envelop: Blank,
      misc_expense: Blank,
      refreshment_amount: Blank,
      gift_amount: Blank,
      positive_points: Blank,
      improvement_points: Blank,
      program_admins: [],
      coordinator_name: Blank,
      coordinator_phone: Blank,
      program_details: [],
      user_instance: Blank,
      images: [],
      program_type_name: Blank,
      created_on: Blank,
      edited_on: Blank
    }
  });
  const today = new Date();
  const formattedTodayDate = today.toISOString().substring(0, 10);
  const [programDate, setProgramDate] = useState(formattedTodayDate);
  const [programImages, setProgramImages] = useState([]);
  if (programInfo.conductionDetail.program_date === Blank) {
    programInfo.conductionDetail.program_date = programDate;
  };
  const [show, setShow] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("Dialog Message");
  const [programDetailInfo, setDetailProgramInfo] = useState([]);
  const [zones, setZones] = useState(Zones);
  const [programTypes, setProgramTypes] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState({ loading: true, error: Blank, loadingMessage: "Loading" });
  const [successInfo, setsuccessInfo] = useState({ show: false, message: Blank });
  const [activityRow, setActivityRow] = useState({ id: 0, activity: { id: 0, duration: 0, participants: [], remark: [], remarks: [], uuid: Blank, users: [], description: Blank }, index: 0 });
  const [users, setUsers] = useState(Users);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [warningDialogMessage, setWarningDialogMessage] = useState(Blank);
  const handleWarningDialogClose = () => setShowWarningDialog(false);
  const { Id } = useParams();
  const [errors, setErrors] = useState<Errors>({});

  const handleWarningDialogAccept = () => {
    setShowWarningDialog(false);
    window.location.href = "/Programs/Add";
  };

  const showWarningDialogHandler = (warningDialogMessage: string) => {
    setShowWarningDialog(true);
    setWarningDialogMessage(warningDialogMessage);
  };

  const programImageshandler = (event: any) => {
    const images = Array.from(event.target.files);
    const clubbedImages = images.concat(programImages);
    let slicedImages = clubbedImages.slice(0, MaxImagesUnit);
    setProgramImages(slicedImages);
  };

  const removeImage = (image: any) => {
    const newImages = programImages.filter((item: any) => item.name !== image.name);
    setProgramImages(newImages);
    if (newImages.length === 0) inputFile.current.value = null;
  };

  const handleAccept = (actionType: string) => {
    /* Refactor below code when you feel more relaxed and loving programming :) */
    if (actionType === Actions.ADD_REMOVE_ROW) {
      let updatedProgramInfo = [...programDetailInfo];
      const activityCopy = updatedProgramInfo.find(object => object.id === activityRow.id);
      if (activityRow.activity.uuid !== Blank) {
        const indexOfactivityToRemove = activityCopy.entries.indexOf(activityRow.activity);
        activityCopy.entries.splice(indexOfactivityToRemove, 1);
      } else {
        let activityRowToAdd = Object.assign({}, activityRow.activity);
        activityRowToAdd.uuid = uuidv4();
        activityRowToAdd.duration = 0;
        activityRowToAdd.participants = [];
        activityRowToAdd.users = users;
        activityRowToAdd.remark = [];
        activityCopy.entries.push(activityRowToAdd);
      }
      setDetailProgramInfo(updatedProgramInfo);
      setActivityRow({ id: 0, activity: { id: 0, duration: 0, participants: [], users: [], remark: [], remarks: [], uuid: Blank, description: Blank }, index: 0 });
    }
    handleClose();
  };

  const findFormErrors = (): Errors => {
    const { zone, program_type, venue, exp_audiance, act_audiance, booking_amount, nazim_report_writer, refreshment_amount, program_admins, program_date, coordinator_phone, molana_envelop, misc_expense } = programInfo.conductionDetail;
    const newErrors: Errors = {};
    if (zone === Blank || zone.length === 0) newErrors.zone = ModelProperties.zone.validationMessage;
    if (program_type === Blank || program_type.length === 0) newErrors.program_type = ModelProperties.program_type.validationMessage;
    if (venue === Blank || venue.length === 0) newErrors.venue = ModelProperties.venue.validationMessage;
    validateNumericValue(exp_audiance, ModelProperties.exp_audiance.name, newErrors);
    validateNumericValue(act_audiance, ModelProperties.act_audiance.name, newErrors);
    validateNumericValue(refreshment_amount, ModelProperties.refreshment_amount.name, newErrors);
    validateNumericValue(booking_amount, ModelProperties.booking_amount.name, newErrors);
    validateNumericValue(molana_envelop, ModelProperties.molana_envelop.name, newErrors);
    validateNumericValue(misc_expense, ModelProperties.misc_expense.name, newErrors);
    if (!nazim_report_writer) newErrors.nazim_report_writer = ModelProperties.nazim_report_writer.validationMessage;
    const regex: RegExp = ModelProperties.program_date.pattern;
    if (!regex.test(program_date)) {
      newErrors.program_date = ModelProperties.program_date.validationMessage;
    };
    if (coordinator_phone) {
      const regex: RegExp = ModelProperties.coordinator_phone.pattern;
      if (!regex.test(coordinator_phone)) {
        newErrors.coordinator_phone = ModelProperties.coordinator_phone.validationMessage;
      }
    }
    if (program_admins.length === 0) newErrors.program_admins = ModelProperties.program_admins.validationMessage;
    return newErrors;
  };

  const validateNumericValue = (value: string, propertyName: string, errors: Errors) => {
    const numericValue: number = Number(value);
    const propertyLabel: string = ModelProperties[propertyName as keyof typeof ModelProperties].label;
    let errorMessage: string = Blank;
    if (Number.isNaN(numericValue)) errorMessage = `${propertyLabel} should be a numeric value`;
    if (ModelProperties[propertyName as keyof typeof ModelProperties].numeric_prop.numeric === true && ModelProperties[propertyName as keyof typeof ModelProperties].numeric_prop.allowedZero === false && numericValue === 0) errorMessage = `${propertyLabel} can not be 0`;
    if (numericValue < 0) errorMessage = `${propertyLabel} can not be negative number`;
    if (ModelProperties[propertyName as keyof typeof ModelProperties].numeric_prop.numeric === true && ModelProperties[propertyName as keyof typeof ModelProperties].numeric_prop.allowedZero === false && value === Blank) errorMessage = `Please provide ${propertyLabel} value`;
    if (ModelProperties[propertyName as keyof typeof ModelProperties].numeric_prop.int === true) {
      if (Number.isInteger(numericValue) === false) errorMessage = `${propertyLabel} should be an integer value`;
    }
    if (errorMessage) {
      setErrors({ ...errors, [propertyName]: errorMessage });
      if (errors) errors[propertyName as keyof typeof errors] = errorMessage;
    }
  };

  const readSyncDataURL = (image: any) => {
    let url = URL.createObjectURL(image);
    let xmlHttpRequest: XMLHttpRequest = new XMLHttpRequest();
    xmlHttpRequest.open(HttpRequestMethods.GET, url, false);
    xmlHttpRequest.overrideMimeType("text/plain; charset=x-user-defined");
    xmlHttpRequest.send();
    URL.revokeObjectURL(url);
    let returnText: string = Blank;
    for (let i: number = 0; i < xmlHttpRequest.responseText.length; i++) {
      returnText += String.fromCharCode(xmlHttpRequest.responseText.charCodeAt(i) & 0xff);
    };
    return `data:${image.type};base64,${window.btoa(returnText)}`;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const formErrors = findFormErrors();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
    
    programInfo.conductionDetail.program_details = programDetailInfo;
    programInfo.conductionDetail.user_instance = GetStorageItem(AppConfigConstant.USER_INSTANCE);
    programInfo.conductionDetail.images = [];
    
    programImages.forEach((image: any) => {
      programInfo.conductionDetail.images.push(readSyncDataURL(image));
    });

    setLoadingInfo({ loading: true, error: Blank, loadingMessage: "Saving Program Detail" });
    const requestOptions = { UUID: GetStorageItem(AppConfigConstant.HASH), UserID: GetStorageItem(AppConfigConstant.IDENTITY), Url: "ProgramDetail", RequestData: programInfo.conductionDetail };
    postData(requestOptions).then(response => {
      if (!response.error) {

        setLoadingInfo({
          loading: false,
          error: Blank,
          loadingMessage: Blank
        });

        if (response.data.statusCode === HTTPStatusCodes.UnAuthorized) {
          ClearStorage();
          window.location.href = AppConfigConstant.LOGIN_URL;
        };

        if (response.data.statusCode === HTTPStatusCodes.OK) {
          setsuccessInfo({
            show: true,
            message: response.data.ResponseData.message
          });
          let updatedProgramInfo = { ...programInfo };
          updatedProgramInfo.conductionDetail.id = response.data.ResponseData.id;
          setProgramInfo(updatedProgramInfo);
        }

        if (response.data.statusCode !== HTTPStatusCodes.OK) {
          setLoadingInfo({
            loading: false,
            error: response.data.ResponseData,
            loadingMessage: Blank
          });
        }

      }

      if (response.error) {
        setLoadingInfo({
          loading: false,
          error: response.errorMessage,
          loadingMessage: Blank
        });
      }

    });
  };

  const textInputValueHandler = (event: any) => {
    if (event.target.name === ModelProperties.program_date.name) {
      const programDate = event.target.value;
      const regex: RegExp = ModelProperties.program_date.pattern;
      setErrors({ ...errors, program_date: Blank });
      if (!regex.test(programDate)) {
        setErrors({ ...errors, program_date: ModelProperties.program_date.validationMessage });
      }
      setProgramDate(event.target.value);
      let updatedProgramInfo = { ...programInfo };
      updatedProgramInfo.conductionDetail[event.target.name as keyof typeof programInfo.conductionDetail] = programDate;
      setProgramInfo(updatedProgramInfo);
    }
  };

  const participantHandeler = (activity: any, data: any, multiple: boolean) => {
    activity[data.name].splice(0, activity[data.name].length);
    if (multiple) {
      data.value.forEach((item: any) => {
        activity[data.name].push(item);
      });
    } else {
      const selectedParticipant = users.find((user: any) => user.value === data.value);
      activity[data.name].push(selectedParticipant);
    };
  };

  const remarkHandler = (activity: any, data: any, multiple: boolean) => {
    const selectedRemark = activity.remarks.find((remark: any) => remark.value === data.value);
    activity[data.name].splice(0, activity[data.name].length);
    activity[data.name].push(selectedRemark);
  };

  const activityNameValueHandler = (activity: any, event: any) => {
    let updatedProgramDetailInfo = [...programDetailInfo];
    activity[event.target.name] = event.target.value;
    setDetailProgramInfo(updatedProgramDetailInfo);
  };

  const addActivityHandler = (id: number, programActivity: any, activityIndex: number, uuid: string) => {
    const activity: any = programDetailInfo.find((object: any) => object.id === id);
    if (activity.multiple && uuid === Blank) {
      setDialogMessage(`${activity.name} row will be added`);
      setActivityRow({ id: id, activity: programActivity, index: activityIndex });
      handleShow();
    }
    if (activity.multiple && uuid !== Blank) {
      setDialogMessage(`${activity.name} row will be removed`);
      setActivityRow({ id: id, activity: programActivity, index: activityIndex });
      handleShow();
    }
  };

  const selectStyles = {
    control: (style: any) => ({ ...style, minHeight: "58px", boxShadow: "none" })
  };

  const programInfoHandler = (event: any) => {
    let updatedProgramInfo = { ...programInfo };
    const field: string = event.target.name;
    const value: string = event.target.value;
    updatedProgramInfo.conductionDetail[field as keyof typeof programInfo.conductionDetail] = value;
    setProgramInfo(updatedProgramInfo);
    if (ModelProperties[field as keyof typeof ModelProperties]) {
      setErrors({ ...errors, [field]: ModelProperties[field as keyof typeof ModelProperties].validationMessage });
      if (value.length > 0 && value !== Blank) setErrors({ ...errors, [field]: Blank });
      if (ModelProperties[field as keyof typeof ModelProperties].numeric_prop.numeric === true && ModelProperties[field as keyof typeof ModelProperties].numeric_prop.allowedZero === true) {
        setErrors({ ...errors, [field]: Blank });
      }
      if (isNumber(value)) validateNumericValue(value, field, errors);
      if (field === ModelProperties.coordinator_phone.name) {
        if (value) {
          const regex: RegExp = ModelProperties.coordinator_phone.pattern;
          if (!regex.test(value)) setErrors({ ...errors, [field]: ModelProperties.coordinator_phone.validationMessage });
        }
      }
    }
  };

  const isNumber = (value: string) => {
    if (!Array.isArray(value)) {
      if (value.trim().length === 0) return false;
      return !isNaN(Number(value));
    }
    return false;
  };

  const setProgramNazim = (nazim: any) => {
    let updatedProgramInfo = { ...programInfo };
    updatedProgramInfo.conductionDetail.nazim_report_writer = nazim;
    setProgramInfo(updatedProgramInfo);
    setErrors({ ...errors, nazim_report_writer: ModelProperties.nazim_report_writer.validationMessage });
    if (updatedProgramInfo.conductionDetail.nazim_report_writer) setErrors({ ...errors, nazim_report_writer: Blank });
  };

  useEffect(() => {
    let isMounted = true;
    const requestOptions = { UUID: GetStorageItem(AppConfigConstant.HASH), UserID: GetStorageItem(AppConfigConstant.IDENTITY), Url: `Program?id=${Id === undefined ? 0 : Id}`, RequestData: { Instance: GetStorageItem(AppConfigConstant.USER_INSTANCE) } };
    if (loadingInfo.loading) {
      getData(requestOptions, HttpRequestMethods.POST).then(response => {
        if (!response.error && isMounted) {
          setLoadingInfo({ loading: false, error: Blank, loadingMessage: Blank });
          if (response.data.statusCode === HTTPStatusCodes.UnAuthorized) {
            ClearStorage();
            window.location.href = AppConfigConstant.LOGIN_URL;
          }
          if (response.data.statusCode === HTTPStatusCodes.OK) {
            setZones(response.data.ResponseData.zones);
            setProgramTypes(response.data.ResponseData.programTypes);
            setUsers(response.data.ResponseData.users);
            dispatch({ type: ActionTypes.SET_API_VERSION, payload: response.data.ResponseData.apiVersion });
            setDetailProgramInfo(response.data.ResponseData.programActivities);
            SetStorage(AppConfigConstant.API_VERSION, response.data.ResponseData.apiVersion);
            if (response.data.ResponseData.program !== null && Number(Id === undefined ? 0 : Id) > 0) {
              /* Set Program Information */
              const program: IProgram = response.data.ResponseData.program;
              let updatedProgramInfo = { ...programInfo };
              Object.keys(updatedProgramInfo.conductionDetail).forEach((key: string) => {
                let value: string = program[key] || Blank;
                if (key === "program_date") value = format(new Date(program.program_date), "yyyy-MM-dd");
                updatedProgramInfo.conductionDetail[key] = value;
              });
              setProgramInfo(updatedProgramInfo);
            }
          }
          if (response.data.statusCode === HTTPStatusCodes.InternalServerError) {
            setLoadingInfo({ loading: false, error: response.data.ResponseData, loadingMessage: Blank });
          }
        }
        if (response.error && isMounted) {
          setLoadingInfo({ loading: false, error: response.errorMessage, loadingMessage: Blank });
        }
      });
    }
    return () => { isMounted = false; };
  }, [dispatch, Id, programInfo, loadingInfo.loading]);

  return (
    <div>
      <SuccessDialog
        message={successInfo.message}
        show={successInfo.show}
        centered={true}
        onHide={() => { setsuccessInfo({ message: Blank, show: false }) }}
      />
      <AIMLoadingSpinner
        show={loadingInfo.loading}
        spinnerText={loadingInfo.loadingMessage}
        variant="light"
        role="status"
        animation="border"
      />
      <AIMDialog
        show={show}
        onHide={handleClose}
        message={dialogMessage}
        closeButtonVariant="secondary"
        closeButtonText="Cancel"
        acceptButtonVariant="success"
        accetButtonText="Ok"
        handleClose={handleClose}
        handleAccept={() => handleAccept(Actions.ADD_REMOVE_ROW)}
      />
      <AIMDialog
        show={showWarningDialog}
        onHide={handleClose}
        message={warningDialogMessage}
        closeButtonVariant="success"
        closeButtonText="Cancel"
        acceptButtonVariant="danger"
        accetButtonText="Yes"
        handleClose={handleWarningDialogClose}
        handleAccept={() => handleWarningDialogAccept()}
      />
      <Form onSubmit={(event) => handleSubmit(event)}>
        <Row>
          <Col xs={6}>
            <span style={{ fontWeight: "500", fontSize: "1.25rem", lineHeight: "38px" }}>Program Details</span>
          </Col>
          <Col xs={6} md={6} style={{ textAlign: "right" }}>
            <BrowserView className="inline">
              <Button variant="danger" type="button" onClick={() => showWarningDialogHandler(Messages.ResetWarning)} className="margin-right-5px">Reset</Button>
            </BrowserView>
            <Button variant="primary" type="submit" disabled={false} className="margin-right-5px">Save</Button>
            <Button variant="secondary" type="button" onClick={() => { navigate("/Programs") }}>Back</Button>
          </Col>
        </Row>
        <hr />
        {loadingInfo.error ?
          <Row>
            <Col xs={12} md={12}>
              <Alert variant="danger">
                <strong>Error: </strong>{loadingInfo.error}
              </Alert>
            </Col>
          </Row>
          : Blank
        }
        <Row>
          <Col xs={12} md={3}>
            <AIMFloatingLabelSelect 
              className="mb-3" 
              name={ModelProperties.zone.name} 
              label={ModelProperties.zone.label} 
              errors={errors} dataItems={zones} 
              onChange={(event: any) => programInfoHandler(event)} 
              selectedValue={programInfo.conductionDetail.zone} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelSelect 
              className="mb-3" 
              name={ModelProperties.program_type.name} 
              label={ModelProperties.program_type.label} 
              errors={errors} 
              dataItems={programTypes} 
              onChange={(event: any) => programInfoHandler(event)} 
              selectedValue={programInfo.conductionDetail.program_type} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelSelect 
              className="mb-3" 
              name="program_conducted" 
              label="Program Conducted" 
              errors={errors} 
              dataItems={[{ id: "1", name: "Yes" }, { id: "0", name: "No" }]} 
              selectedValue={programInfo.conductionDetail.program_conducted} 
              blankOption={false} 
              onChange={(event: any) => programInfoHandler(event)} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label={ModelProperties.program_date.label} 
              type="date" 
              name={ModelProperties.program_date.name} 
              errors={errors} 
              defaultValue={programInfo.conductionDetail.program_date} 
              eventHandler={(event: any) => textInputValueHandler(event)} 
              maxValue={formattedTodayDate} 
              required={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <Form.Group className="mb-3">
              <Select
                name={ModelProperties.nazim_report_writer.name}
                options={users}
                isSearchable={true}
                isClearable={true}
                placeholder={ModelProperties.nazim_report_writer.label}
                styles={selectStyles}
                defaultValue={programInfo.conductionDetail.nazim_report_writer}
                value={programInfo.conductionDetail.nazim_report_writer}
                onChange={(nazim: any) => setProgramNazim(nazim)}
                className={errors.nazim_report_writer?.length > 0 ? "select-error" : Blank}
              />
              <span className={programInfo.conductionDetail.nazim_report_writer ? Blank : "hide"}>
                <Form.Text className="text-muted">{ModelProperties.nazim_report_writer.label}</Form.Text>
              </span>
              <span className={errors.nazim_report_writer === Blank ? "hide" : Blank}>
                <Form.Text className="error-red">{errors.nazim_report_writer}</Form.Text>
              </span>
            </Form.Group>
          </Col>
          <Col xs={12} md={3}>
            <Form.Group className="mb-3">
              <AIMFloatingLabelInput 
                className="mb-3" 
                label={ModelProperties.venue.label} 
                type="text" 
                name={ModelProperties.venue.name} 
                errors={errors} autoComplete="off" 
                defaultValue={programInfo.conductionDetail.venue} 
                eventHandler={(event: any) => programInfoHandler(event)} 
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label={ModelProperties.exp_audiance.label} 
              type="number" name={ModelProperties.exp_audiance.name} 
              errors={errors} 
              defaultValue={programInfo.conductionDetail.exp_audiance} 
              eventHandler={(event: any) => programInfoHandler(event)} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label={ModelProperties.act_audiance.label} 
              type="number" name={ModelProperties.act_audiance.name} 
              errors={errors} 
              defaultValue={programInfo.conductionDetail.act_audiance} 
              eventHandler={(event: any) => programInfoHandler(event)} 
            />
          </Col>
        </Row>
        <h5>Program Activities</h5><hr />
        <ProgramActivities 
          programDetailInfo={programDetailInfo} 
          MultiSelectOptions={users} 
          activityHandler={(id: any, activity: any, activityIndex: number, uuid: string) => addActivityHandler(id, activity, activityIndex, uuid)} 
          valueHandler={(activity: any, event: any) => activityNameValueHandler(activity, event)} 
          participantHandeler={(activity: any, data: any, multiple: boolean) => participantHandeler(activity, data, multiple)} 
          remarkHandler={(activity: any, data: any, multiple: boolean) => remarkHandler(activity, data, multiple)} 
          errors={errors} 
        />
        <h5>Expense Details</h5><hr />
        <Row>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label="Booking Amount (INR)" 
              type="number" 
              name="booking_amount" 
              errors={errors} 
              formText="Should be numeric value in INR" 
              eventHandler={(event: any) => programInfoHandler(event)} 
              defaultValue={programInfo.conductionDetail.booking_amount} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label="Maulana Envelop" 
              type="number" 
              name="molana_envelop" 
              errors={errors} 
              formText="Should be numeric value in INR" 
              eventHandler={(event: any) => programInfoHandler(event)} 
              defaultValue={programInfo.conductionDetail.molana_envelop} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label="Refreshment Amount" 
              type="number" 
              name="refreshment_amount" 
              errors={errors} 
              formText="Should be numeric value in INR" 
              eventHandler={(event: any) => programInfoHandler(event)} 
              defaultValue={programInfo.conductionDetail.refreshment_amount} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label="Gift Amount" 
              type="number" 
              name="gift_amount" 
              errors={errors} 
              formText="Should be numeric value in INR" 
              eventHandler={(event: any) => programInfoHandler(event)} 
              defaultValue={programInfo.conductionDetail.gift_amount} 
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label="Miscellaneous" 
              type="number" 
              name="misc_expense" 
              errors={errors} 
              formText="Should be numeric value in INR" 
              eventHandler={(event: any) => programInfoHandler(event)} 
              defaultValue={programInfo.conductionDetail.misc_expense} 
            />
          </Col>
        </Row>
        <h5>Observation</h5><hr />
        <Row>
          <Col xs={12} md={6}>
            <FloatingLabel label="Positive Points" className="mb-3">
              <Form.Control 
                as="textarea" 
                placeholder="Positive Points" 
                style={{ height: "150px" }} 
                onChange={(event: any) => programInfoHandler(event)} 
                defaultValue={programInfo.conductionDetail.positive_points} 
                name="positive_points" 
              />
            </FloatingLabel>
          </Col>
          <Col xs={12} md={6}>
            <FloatingLabel label="Points of Improvement / Non conduction" className="mb-3">
              <Form.Control 
                as="textarea" 
                placeholder="Points of Improvement / Non conduction" 
                style={{ height: "150px" }} 
                onChange={(event: any) => programInfoHandler(event)} 
                defaultValue={programInfo.conductionDetail.improvement_points} 
                name="improvement_points" 
              />
            </FloatingLabel>
          </Col>
        </Row>
        <h5>Program Admins</h5><hr />
        <Row>
          <Col xs={12} md={3}>
            <Select 
              options={users} 
              isMulti={true} 
              isSearchable={true} 
              placeholder={ModelProperties.program_admins.label} 
              styles={selectStyles} 
              onChange={(event) => programInfoHandler({ target: { name: ModelProperties.program_admins.name, value: event } })} 
              defaultValue={programInfo.conductionDetail.program_admins} 
              className={errors.program_admins?.length > 0 ? "select-error" : Blank} 
            />
            <span className={errors.program_admins === Blank ? "hide" : Blank}>
              <Form.Text 
                className="error-red">{errors.program_admins}
              </Form.Text>
            </span>
          </Col>
        </Row>
        <br />
        <h5>Program Coordinator</h5><hr />
        <Row>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label="Coordinator Name" 
              type="text" 
              name="coordinator_name" 
              errors={errors} 
              autoComplete="off" 
              defaultValue={programInfo.conductionDetail.coordinator_name} 
              eventHandler={(event: any) => programInfoHandler(event)} 
            />
          </Col>
          <Col xs={12} md={3}>
            <AIMFloatingLabelInput 
              className="mb-3" 
              label="Coordinator Phone" 
              type="text" 
              name="coordinator_phone" 
              errors={errors} 
              autoComplete="off" 
              defaultValue={programInfo.conductionDetail.coordinator_phone} 
              maxLength="10" 
              eventHandler={(event: any) => programInfoHandler(event)} 
            />
          </Col>
        </Row>
        <div style={{ fontWeight: "500", fontSize: "1.25rem", marginBottom: "0px" }}>Images</div><div style={{ fontSize: "12px", fontStyle: "italic" }}>Maximum 4 images (1920 X 1080 resolution for best result)</div><hr />
        <Row>
          <Col xs={12} md={3}>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <input 
                type="file" 
                id="pgm_images" 
                ref={inputFile} 
                multiple 
                onChange={(event: any) => programImageshandler(event)} 
                accept="image/png, image/gif, image/jpeg, image/jpg" 
                style={{ display: "none" }} />
              <Button variant="primary" type="button" onClick={() => { inputFile.current.click(); }}>Choose Images</Button>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {
            programImages.map((image: any, index: number) =>
              (
                <Col xs={12} md={3} key={index}>
                  <div className="img-container">
                    <img src={URL.createObjectURL(image)} alt={image.name} className="img-thumbnail" />
                  </div>
                  <div style={{ cursor: "pointer", color: "#0d6efd", textAlign: "center" }} onClick={() => removeImage(image)}>Remove Image</div>
                </Col>
              )
            )
          }
        </Row>
        <br />
        <Row>
          <Col xs={12} md={12} style={{ textAlign: "right" }}>
            <Button variant="danger" type="button" onClick={() => showWarningDialogHandler(Messages.ResetWarning)} className="margin-right-5px">Reset</Button>
            <Button variant="primary" type="submit" disabled={false} className="margin-right-5px">Save</Button>
            <Button variant="secondary" type="button" onClick={() => { navigate("/Programs") }}>Back</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Program;