import AppName from "../Header/AppName";
import { useEffect, useState } from "react";
import { ApplicationVersion } from "../Constants";
import { GetStorageItem } from "../Helpers/Authentication";
import { AppConfigConstant } from "../AppConfig";
import { Blank } from "../Constants";
import { useSelector, RootStateOrAny } from "react-redux";
const Footer = () => {
  let apiVersion = useSelector((state: RootStateOrAny) => state.userData.apiVersionInfo);
  if(!apiVersion) apiVersion = GetStorageItem(AppConfigConstant.API_VERSION);
  const [displayApiVersion, setDisplayApiVersion] = useState(apiVersion);
  const date = new Date();
  useEffect(() => {
    setDisplayApiVersion(apiVersion);
  },[apiVersion, displayApiVersion, setDisplayApiVersion]);
  return (
    <div className="footer">
      <div className="copy">&copy;{date.getFullYear()} Association of Imam Mahdi (a.t.f.s.)</div>
      <div className="version"><AppName /> Version: {ApplicationVersion}</div>
      <div className="version">
        {displayApiVersion !== Blank ? "TAC API Version: " + displayApiVersion : Blank}
      </div>
    </div>
  );
};

export default Footer;