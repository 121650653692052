import { Form, FloatingLabel } from "react-bootstrap";
import { Blank } from "../Constants";

export const AIMSelect = (props: any) => {
  if(props.blankOption === false) {
    return (
      <>
        <Form.Select 
          name={props.name} 
          value={props.selectedValue} 
          onChange={props.onChange} 
          isInvalid={ !!props.errors[props.name] }>
          {
            props.dataItems.map((item: any) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))
          }
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {props.errors[props.name]}
        </Form.Control.Feedback>
      </>
    );
  }
  return (
    <>
      <Form.Select 
        name={props.name} 
        value={props.selectedValue} 
        onChange={props.onChange} 
        isInvalid={ !!props.errors[props.name] }>
          <option value={Blank}></option>
          {
            props.dataItems.map((item: any) => (
              <option key={item.id} value={item.id}>{item.name}</option>
            ))
          }
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {props.errors[props.name]}
      </Form.Control.Feedback>
    </>
  );
};

export const AIMSelectWithFormGroup = (props: any) => {
  return (
    <Form.Group className={props.className}>
      <Form.Label>{props.label}</Form.Label>
      <AIMSelect 
        name={props.name} 
        dataItems={props.dataItems} 
        blankOption={props.blankOption} 
        selectedValue={props.selectedValue} 
        onChange={props.onChange} 
        errors={props.errors} 
      />
    </Form.Group>
  );
};

export const AIMFloatingLabelSelect = (props: any) => {
  return (
    <Form.Group className={props.className}>
      <FloatingLabel controlId={props.controlId} label={props.label}>
        <AIMSelect 
          name={props.name} 
          dataItems={props.dataItems} 
          blankOption={props.blankOption} 
          selectedValue={props.selectedValue} 
          onChange={props.onChange} 
          errors = {props.errors} 
        />
      </FloatingLabel>
    </Form.Group>
  );
};