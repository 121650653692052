import { useEffect } from "react";
import { GetStorageItem, ClearStorage } from "./Helpers/Authentication";
import { AppConfigConstant } from "./AppConfig";

const CheckAuth = (): any => {
  /*const userLoggedIn = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.isLoggedIn);*/
  const userLoggedIn = GetStorageItem(AppConfigConstant.IDENTITY);
  useEffect(() => {
    if (!userLoggedIn) {
      ClearStorage();
      window.location.href = AppConfigConstant.LOGIN_URL;
    }
  }, [userLoggedIn]);
  if (!userLoggedIn) window.location.href = AppConfigConstant.LOGIN_URL;
  if (userLoggedIn) return (null);
  return (null);
};

export default CheckAuth;