import { Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { AIMLoadingSpinner } from "../../Shared";
import { getData } from "../../Helpers/Fetch";
import { Blank, HttpRequestMethods, HTTPStatusCodes } from "../../Constants";
import { GetStorageItem, ClearStorage } from "../../Helpers/Authentication";
import { AppConfigConstant } from "../../AppConfig";
import IProgram from "../../Models/Interfaces/IProgram";
import { format } from "date-fns";
import {FaPencilAlt} from "react-icons/fa"


const Programs = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [programs, setPrograms] = useState<IProgram[]>([]);
  const navigate = useNavigate();
  const addEnryHandler = () => {
    navigate("/Programs/Add");
  };

  useEffect(() => {
    let isMounted = true;
    if (loading) {
      const requestOptions = { UUID: GetStorageItem(AppConfigConstant.HASH), UserID: GetStorageItem(AppConfigConstant.IDENTITY), Url: "ProgramDetail/GetAll", RequestData: { Instance: GetStorageItem(AppConfigConstant.USER_INSTANCE) } };
      getData(requestOptions, HttpRequestMethods.POST).then(response => {
        if (!response.error && isMounted) {
          setLoading(false);
          if (response.data.statusCode === HTTPStatusCodes.OK) {
            setPrograms(response.data.ResponseData.programs);
          }
          if (response.data.statusCode === HTTPStatusCodes.UnAuthorized) {
            ClearStorage();
            window.location.href = AppConfigConstant.LOGIN_URL;
          }
        }
      })
    }
    return () => { isMounted = false };
  });

  return (
    <div>
      <Row>
        <Col xs={6}>
          <span style={{ fontWeight: "500", fontSize: "1.25rem", lineHeight: "38px" }}>Programs</span>
        </Col>
        <Col xs={6} md={6} style={{ textAlign: "right" }}>
          <Button variant="primary" onClick={addEnryHandler}>New Entry</Button>
        </Col>
      </Row>
      <hr />
      <AIMLoadingSpinner
        show={loading}
        spinnerText="Fetching Programs"
        variant="light"
        role="status"
        animation="border"
      />
      <Table striped hover responsive className={loading === true ? "hide" : Blank}>
        <thead>
          <tr className="no_wrap">
            <th>Zone</th>
            <th>Program Type</th>
            <th>Venue</th>
            <th>Program Date</th>
            <th>Conducted</th>
            <th>Exp. Audiance</th>
            <th>Act. Audiance</th>
            <th>Entry Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            programs.map((program: IProgram) => (
              <tr key={program.id} className="no_wrap clickable"  onClick={()=>{navigate(`/Programs/Add/${program.id}`)}}>
                <td>{program.zone}</td>
                <td>{program.program_type_name}</td>
                <td>{program.venue}</td>
                <td>{format(new Date(program.program_date), "dd-MMM-yyyy")}</td>
                <td>{program.program_conducted === 1 ? "Yes" : "No"}</td>
                <td>{program.exp_audiance}</td>
                <td>{program.act_audiance}</td>
                <td>{format(new Date(program.created_on), "dd-MMM-yyyy hh:mm a")}</td>
                <td><span className="link" title="Edit Entry"><FaPencilAlt /></span></td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </div>
  );
};
export default Programs;