export const AIMTableRowHeader = (props: any) => {
  return (
    <thead>
      <tr>
        {
          props.rowHeaders.map((header: string, index: number) => (
            <th key={index}>{header}</th>
          ))
        }
      </tr>
    </thead>
  );
};