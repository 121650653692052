import { AppConfigConstant } from "./AppConfig";

export const HTTPStatusCodes = {
  OK: 200,
  NotAcceptable: 406,
  UnAuthorized: 401,
  InternalServerError: 500
};

export const HttpRequestMethods = {
  POST: "POST",
  GET: "GET"
};

export const Blank : string = "";
export const Unit : number = 1;
export const MaxImagesUnit : number = 4;
export const Actions = {
  ADD_REMOVE_ROW: "ADD_REMOVE_ROW"
};

export const ErrorMessages = {
  NetworkError : "Network error, please try again by reloading page",
  InernalServerError : "Internal server error, please try again later",
};

export const Messages = {
  ResetWarning : "Your unsaved data will be lost, do you want to continue?"
};

export const API_URL: string = `${AppConfigConstant.API_URL}Proxy`;

/* 
  Don't change ApplicationVersion value
  It is build number and used as place holder to generate app version by build pipeline on GitHub
*/
export const ApplicationVersion= "1.2.0.7+release:2e729dd.14"; 
