const BASE_URL = "https://sso.almsoftware.in/";
const APP_CODE = "TACAPP";

export const AppConfigConstant = {
  APP_CODE: APP_CODE,
  LOGIN_URL: BASE_URL + "Authenticate/Login?AppCode=" + APP_CODE,
  API_URL :  BASE_URL + "Api/",
  LOGOUT_URL: BASE_URL + "Authenticate/Logout?AppCode=" + APP_CODE,
  HASH: "HASH",
  IDENTITY: "IDENTITY",
  USER_INSTANCE: "USER_INSTANCE",
  AVAI_INSTANCES: "AVAI_INSTANCES",
  API_VERSION: "API_VERSION"
}