export const ModelProperties = {
  nazim_report_writer: {
    name: "nazim_report_writer",
    validationMessage: "Please select a Nazim/Report Writer",
    label: "Nazim/Report Writer",
    numeric_prop :{
      numeric: false,
      int: false,
      allowedZero: true
    }
  },
  zone: {
    name: "zone",
    validationMessage: "Please select a Zone",
    label: "Zone",
    numeric_prop :{
      numeric: false,
      int: false,
      allowedZero: true
    }
  },
  program_type: {
    name: "program_type",
    validationMessage: "Please select a Program Type",
    label: "Program Type",
    numeric_prop :{
      numeric: false,
      int: false,
      allowedZero: true
    }
  },
  venue: {
    name: "venue",
    validationMessage: "Please provide program Venue",
    label: "Venue",
    numeric_prop :{
      numeric: false,
      int: false,
      allowedZero: true
    }
  },
  exp_audiance: {
    name: "exp_audiance",
    validationMessage: "Please provide Expected Audiance value",
    label: "Expected Audiance",
    numeric_prop :{
      numeric: true,
      int: true,
      allowedZero: false
    }
  },
  act_audiance: {
    name: "act_audiance",
    validationMessage: "Please provide Actual Audiance value",
    label: "Actual Audiance",
    numeric_prop :{
      numeric: true,
      int: true,
      allowedZero: false
    }
  },
  refreshment_amount: {
    name: "refreshment_amount",
    validationMessage: "Please provide Refreshment Amount value",
    label: "Refreshment Amount",
    numeric_prop :{
      numeric: true,
      int: false,
      allowedZero: true
    }
  },
  molana_envelop: {
    name: "molana_envelop",
    validationMessage: "Please provide Maulana Envelop value",
    label: "Maulana Envelop",
    numeric_prop :{
      numeric: true,
      int: false,
      allowedZero: true
    }
  },
  booking_amount: {
    name: "booking_amount",
    validationMessage: "Please provide Booking Amount value",
    label: "Booking Amount",
    numeric_prop :{
      numeric: true,
      int: false,
      allowedZero: true
    }
  },
  gift_amount: {
    name: "gift_amount",
    validationMessage: "Please provide Gift Amount value",
    label: "Gift Amount",
    numeric_prop :{
      numeric: true,
      int: false,
      allowedZero: true
    }
  }, 
  misc_expense: {
    name: "misc_expense",
    validationMessage: "Please provide Miscellaneous expense value",
    label: "Miscellaneous",
    numeric_prop :{
      numeric: true,
      int: false,
      allowedZero: true
    }
  },
  program_admins: {
    name: "program_admins",
    validationMessage: "Please select a Program Admin",
    label: "Program Admins",
    numeric_prop :{
      numeric: false,
      int: false,
      allowedZero: true
    }
  },
  program_date: {
    name: "program_date",
    validationMessage: "Not a valid date [yyyy-mm-dd]",
    label: "Program Date",
    numeric_prop :{
      numeric: false,
      int: false,
      allowedZero: true
    },
    pattern: /^\d{4}-\d{2}-\d{2}$/
  },
  coordinator_phone:{
    name: "coordinator_phone",
    validationMessage: "Please provide 10 digit mobile number",
    label: "10 digit mobile number",
    numeric_prop :{
      numeric: true,
      int: true,
      allowedZero: true
    },
    pattern: /[0-9]{10}/
  }
};