import { useCallback } from "react";
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { ActionTypes } from "../Redux/Constants/action-types";
import { Blank, HttpRequestMethods, HTTPStatusCodes } from "../Constants";
import { AppConfigConstant } from "../AppConfig";
import useFetch from "../Hooks/UseFetch";

export function ValidateTicket(AuthTicket = ""): any {
  const dispatch = useDispatch();
  let savedAuthTicket = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.authTicket);
  savedAuthTicket = sessionStorage.getItem(AppConfigConstant.HASH);
  if (AuthTicket === "") {
    AuthTicket = savedAuthTicket;
  }
  const ticket = AuthTicket;
  let url: string = `${AppConfigConstant.API_URL}ValidateTicket`;
  const requestOptions = { Token: ticket, AppCode: AppConfigConstant.APP_CODE };
  const handleCallBack = useCallback(
    (responseObject: any) => {
      if (responseObject.data.StatusCode === HTTPStatusCodes.OK) {
        dispatch({ type: ActionTypes.SET_LOGIN, payload: { loginStatus: true, id: responseObject.data.ResponseMessage, authTicket: AuthTicket } });
        /* Save information to Session Storage */
        sessionStorage.setItem(AppConfigConstant.HASH, AuthTicket);
        sessionStorage.setItem(AppConfigConstant.IDENTITY, responseObject.data.ResponseMessage);
      }
    }, [AuthTicket, dispatch]);
  const { data: responseData, error, errorMessage } = useFetch(url, HttpRequestMethods.POST, requestOptions, false, null, handleCallBack);
  return { responseData, error, errorMessage };
};

export function ValidateAuthTicket(): boolean {
  const dispatch = useDispatch();
  let savedAuthTicket = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.authTicket);
  let url: string = `${AppConfigConstant.API_URL}ValidateTicket`;
  const requestOptions = { Token: savedAuthTicket, AppCode: AppConfigConstant.APP_CODE };
  fetch(url, {
    method: HttpRequestMethods.POST, headers: { "Content-Type": "application/json", },
    body: JSON.stringify(requestOptions),
  }).then(response => response.json()).then(data => {
    if (data.StatusCode !== HTTPStatusCodes.OK) {
      return false;
    }
    if (data.StatusCode === HTTPStatusCodes.NotAcceptable) {
      dispatch({ type: ActionTypes.SET_LOGIN, payload: { loginStatus: true, id: data.ResponseMessage, authTicket: savedAuthTicket, instance: Blank } });
      return false;
    }
    if (data.StatusCode === HTTPStatusCodes.OK) {
      return true;
    }
  }).catch((error) => {
    return false;
  });
  return false;
};

export function ClearStorage() {
  localStorage.removeItem(AppConfigConstant.IDENTITY);
  localStorage.removeItem(AppConfigConstant.HASH);
  localStorage.removeItem(AppConfigConstant.AVAI_INSTANCES);
  localStorage.removeItem(AppConfigConstant.USER_INSTANCE);
  localStorage.removeItem(AppConfigConstant.API_VERSION);
};

export function SetStorage(key: string, value: string) {
  localStorage.setItem(key, value);
};

export function GetStorageItem(key: string): string {
  return localStorage.getItem(key);
};