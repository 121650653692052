import { GetStorageItem } from "../../Helpers/Authentication";
import { AppConfigConstant } from "../../AppConfig";

const Home = () => {
  const userId = GetStorageItem(AppConfigConstant.IDENTITY);
  return (
    <>
      <h1>Home Page for {userId}</h1>
    </>
  );
};
export default Home;