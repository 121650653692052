import IMenu from "./Models/Interfaces/IMenu";
import Home from "./Components/Home/Home";
import Program from "./Components/Programs/Program";
import Auth from "./Auth";
import About from "./Components/About/About";
import Programs from "./Components/Programs/Programs";
import { Blank } from "./Constants";
import PageNotFound from "./PageNotFound";

const Menus: IMenu[] = [
  {
    id: 0,
    name: Blank,
    linkmenu: "*",
    component: PageNotFound,
    show: false,
    submenu: undefined
  },
  {
    id: 1,
    name: "Home",
    linkmenu: "/Home",
    component: Home,
    show: true,
    submenu: undefined
  },
  {
    id: 2,
    name: "Programs",
    linkmenu: "/Programs",
    component: Programs,
    show: true,
    submenu: undefined
  },
  {
    id: 21,
    name: "Programs",
    linkmenu: "Programs/Add",
    component: Program,
    show: false,
    submenu: undefined
  },
  {
    id: 22,
    name: "Programs",
    linkmenu: "Programs/Add/:Id",
    component: Program,
    show: false,
    submenu: undefined
  },
  {
    id: 3,
    name: "Auth",
    linkmenu: "/Auth",
    component: Auth,
    show: false,
    submenu: undefined
  },
  {
    id: 4,
    name: "About",
    linkmenu: "/About",
    component: About,
    show: true,
    submenu: undefined
  },
  {
    id: 5,
    name: "Dropdown",
    linkmenu: "#",
    component: PageNotFound,
    show: true,
    submenu: [
      {
        id: 51,
        name: "Action",
        linkmenu: "Action/1",
        component: PageNotFound,
        show: true,
        submenu: undefined
      },
      {
        id: 52,
        name: "Another Action",
        linkmenu: "Action/2",
        component: PageNotFound,
        show: true,
        submenu: undefined
      },
      {
        id: 53,
        name: "Something",
        linkmenu: "Action/3",
        component: PageNotFound,
        show: true,
        submenu: undefined
      },
      {
        id: 54,
        name: Blank,
        linkmenu: Blank,
        component: PageNotFound,
        show: true,
        submenu: undefined
      },
      {
        id: 55,
        name: "Separated Link",
        linkmenu: "Action/4",
        component: PageNotFound,
        show: true,
        submenu: undefined
      }
    ]
  }
];

export default Menus;