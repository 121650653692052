import { Form, FloatingLabel } from "react-bootstrap";

export const AIMInput = (props: any) => {
  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control 
        type={props.type} 
        placeholder={props.placeholder} 
        name={props.name} 
        value={props.defaultValue} 
        onChange={props.eventHandler} 
      />
    </>
  );
};

export const AIMInputWithFormGroup = (props: any) => {
  if (props.formText) {
    return (
      <Form.Group className={props.className}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Control 
          type={props.type} 
          placeholder={props.placeholder} 
          name={props.name} 
          value={props.defaultValue} 
          onChange={props.eventHandler} 
        />
        <Form.Text className="text-muted">{props.formText}</Form.Text>
      </Form.Group>
    );
  }
  return (
    <Form.Group className={props.className}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control type={props.type} placeholder={props.placeholder} name={props.name} value={props.defaultValue} onChange={props.eventHandler} />
    </Form.Group>
  );
};


export const AIMFloatingLabelInput = (props: any) => {
  if (props.formText) {
    return (
      <>
        <Form.Group className={props.className}>
          <FloatingLabel controlId={props.controlId} label={props.label} className={props.className}>
            <Form.Control type={props.type} placeholder={props.label} name={props.name} value={props.defaultValue} onChange={props.eventHandler} max={props.maxValue} required={props.required} autoComplete={props.autoComplete} isInvalid={!!props.errors[props.name]} maxLength={props?.maxLength} pattern={props?.pattern} />
            <Form.Control.Feedback type="invalid">
              {props.errors[props.name]}
            </Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </>
    );
  }
  return (
    <>
      <Form.Group className={props.className}>
        <FloatingLabel controlId={props.controlId} label={props.label} className={props.className}>
          <Form.Control type={props.type} placeholder={props.label} name={props.name} value={props.defaultValue} onChange={props.eventHandler} max={props.maxValue} required={props.required} autoComplete={props.autoComplete} isInvalid={!!props.errors[props.name]} maxLength={props?.maxLength} pattern={props?.pattern} />
          <Form.Control.Feedback type="invalid">
            {props.errors[props.name]}
          </Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>
    </>
  );
};