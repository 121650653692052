import React, { useEffect, useState } from "react";
import NavBar from "./Header/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Auth from "./Auth";
import { GetStorageItem } from "./Helpers/Authentication";
import { AppConfigConstant } from "./AppConfig";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { ActionTypes } from "./Redux/Constants/action-types";
import Menus from "./Menus";

const App: React.FC = () => {
  const userLoggedIn = GetStorageItem(AppConfigConstant.IDENTITY);
  const [isAuthenticated] = useState(userLoggedIn);
  let stateUserLoggedIn = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.aimID);
  if (!stateUserLoggedIn) stateUserLoggedIn = userLoggedIn;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: ActionTypes.SET_LOGIN, payload: { loginStatus: (userLoggedIn ? true : false), id: userLoggedIn, authTicket: GetStorageItem(AppConfigConstant.HASH), instance: GetStorageItem(AppConfigConstant.USER_INSTANCE), showInstanceSelector: false } });
    dispatch({ type: ActionTypes.SET_API_VERSION, payload: GetStorageItem(AppConfigConstant.API_VERSION) });
    dispatch({ type: ActionTypes.SET_SHOW_INSTANCE_SELECTOR, payload: (JSON.parse(GetStorageItem(AppConfigConstant.AVAI_INSTANCES))?.length === 0) });
  }, [isAuthenticated, userLoggedIn, dispatch]);

  const ProtectedRoute = (props: any) => {
    return props.component
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="Auth/:userId" element={<Auth />} />
        <Route path="/" element={<NavBar />}>
          <Route index element={<Home />} />
          {
            Menus.map(({id, linkmenu, component: Component}) => {
              return <Route key={id} path={linkmenu} element={<ProtectedRoute component={<Component />} />} />
            })
          }
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;