import React, { useEffect, useState } from "react";
import { Outlet, NavLink } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import AppName from "./AppName";
import { AppConfigConstant } from "../AppConfig";
import { ClearStorage, GetStorageItem } from "../Helpers/Authentication";
import Footer from "../Footer/Footer";
import { Instance } from "../Shared";
import { Blank, Unit } from "../Constants";
import { ActionTypes } from "../Redux/Constants/action-types";
import Instances from "../Shared/Instances";
import CheckAuth from "../CheckAuth";
import Menus from "../Menus";
import IMenu from "../Models/Interfaces/IMenu";

const NavBar: React.FC = () => {
  let userLoggedIn = GetStorageItem(AppConfigConstant.IDENTITY);
  const stateUserLoggedIn = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.aimID);
  const availableInstances = JSON.parse(GetStorageItem(AppConfigConstant.AVAI_INSTANCES));
  const dispatch = useDispatch();
  if (!userLoggedIn) userLoggedIn = stateUserLoggedIn;
  const [isAuthenticated, setIsAuthenticated] = useState(userLoggedIn);

  const logOut = () => {
    ClearStorage();
    window.location.href = AppConfigConstant.LOGOUT_URL;
  };

  const showInstances = () => {
    dispatch({ type: ActionTypes.SET_SHOW_INSTANCE_SELECTOR, payload: true });
  };

  useEffect(() => {
    setIsAuthenticated(userLoggedIn);
  }, [stateUserLoggedIn, userLoggedIn]);

  return (
    <>
      <CheckAuth />
      <Navbar bg="primary" variant="dark" expand="lg" collapseOnSelect fixed="top" className="navbar-sticky" >
        <Container>
          <Navbar.Brand as={NavLink} to="/Home"><AppName /> <Instance /></Navbar.Brand>
          {isAuthenticated ?
            <>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  {
                    Menus.map((menu: IMenu) => {
                      if (!menu.show) return null;
                      return (
                        <span key={`menu_${menu.id}`}>
                          {
                            menu.submenu !== undefined ?
                            <NavDropdown key={menu.id} title={menu.name} id="basic-nav-dropdown">
                              {
                                menu.submenu.map((submenu: IMenu) => {
                                  return (
                                    <span key={`submenu_${submenu.id}`}>
                                      {
                                        submenu.show === false ? Blank :
                                        submenu.linkmenu === Blank ?
                                        <NavDropdown.Divider /> :
                                        <NavDropdown.Item key={submenu.id} as={NavLink} to={submenu.linkmenu}>{submenu.name}</NavDropdown.Item>
                                      }
                                    </span>
                                  )
                                })
                              }
                            </NavDropdown>
                            :
                            <Nav.Link key={menu.id} as={NavLink} to={menu.linkmenu}>{menu.name}</Nav.Link>
                          }
                        </span>
                      )
                    })
                  }
                </Nav>
                <Nav>
                  <NavDropdown title={GetStorageItem(AppConfigConstant.IDENTITY) === null ? Blank : `AIM ID: ${GetStorageItem(AppConfigConstant.IDENTITY)}`} id="basic-nav-dropdown">
                    <NavDropdown.Item hidden={availableInstances?.length === Unit} onClick={showInstances}>Instances</NavDropdown.Item>
                    <NavDropdown.Divider hidden={availableInstances?.length === Unit} />
                    <NavDropdown.Item onClick={logOut}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
              <Instances />
            </> : <></>
          }
        </Container>
      </Navbar>
      <Container className="pad-top-15px">
        <Outlet />
      </Container>
      <Footer />
    </>
  );
};

export default NavBar;