export const Users: any = [];
export const Zones: any = [];

export type Errors = {
  zone?: string;
  program_type?: string;
  venue?: string;
  exp_audiance?: string;
  act_audiance?: string;
  booking_amount?: string;
  nazim_report_writer?: string;
  refreshment_amount?: string;
  program_admins?: string;
  program_date?: string;
  user_instance?: string;
  coordinator_phone?: string;
};