import { Blank } from "../../Constants";
import { ActionTypes } from "../Constants/action-types";

const programDetail: any = [];
const initialState = {
  userLoginInfo: { isLoggedIn: false, aimID: Blank, authTicket: Blank, selectedInstance: Blank, showInstanceSelector: false },
  apiVersionInfo: Blank,
  programDetails:  programDetail,
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_LOGIN:
      return { ...state, userLoginInfo: { isLoggedIn: action.payload.loginStatus, aimID: action.payload.id, authTicket: action.payload.authTicket, selectedInstance: action.payload.instance, showInstanceSelector: action.payload.showInstanceSelector } };
    case ActionTypes.SET_APP_INSTANCE:
      return { ...state, userLoginInfo: { ...state.userLoginInfo, selectedInstance: action.payload } };
    case ActionTypes.SET_IDENTITY:
      return { ...state, userLoginInfo: { ...state.userLoginInfo, aimID: action.payload } };
    case ActionTypes.SET_API_VERSION:
      return { ...state, apiVersionInfo: action.payload }
    case ActionTypes.SET_SHOW_INSTANCE_SELECTOR:
      return { ...state, userLoginInfo: { ...state.userLoginInfo, showInstanceSelector: action.payload } };
    case ActionTypes.SET_PROGRAM_DETAILS:
      return { ...state, programDetails: action.payload };
    default:
      return state;
  }
}

