import { Button, Modal, Spinner } from "react-bootstrap";
import AppName from "../Header/AppName";
import { GetStorageItem } from "../Helpers/Authentication";
import { AppConfigConstant } from "../AppConfig";
import { RootStateOrAny, useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const AIMDialog = (props: any) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} centered={props.centered} backdrop={props.backdrop} >
      <Modal.Header>
        <Modal.Title><AppName /></Modal.Title>
      </Modal.Header>
      <Modal.Body><span className={props?.class}>{props.message}</span></Modal.Body>
      <Modal.Footer>
        <Button variant={props.closeButtonVariant} onClick={props.handleClose}>
          {props.closeButtonText}
        </Button>
        <Button variant={props.acceptButtonVariant} onClick={props.handleAccept}>
          {props.accetButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const Instance = () => {
  let userSelectedSavedInstance = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.selectedInstance);
  const [applicationInstance, setApplicationInstance] = useState(userSelectedSavedInstance);
  if (!userSelectedSavedInstance) {
    const getSavedInstance = GetStorageItem(AppConfigConstant.USER_INSTANCE);
    if (getSavedInstance) userSelectedSavedInstance = getSavedInstance;
  }
  useEffect(() => {
    setApplicationInstance(userSelectedSavedInstance);
  }, [userSelectedSavedInstance]);
  return (
    <span className="instance">{applicationInstance}</span>
  );
};

export const AIMLoadingSpinner = (props: any) => {
  if (props.spinnerText) {
    return (
      <Modal show={props.show} centered className="loadingspinner">
        <Modal.Body className="text-center">
          <Spinner animation={props.animation} variant={props.variant} role={props.role}></Spinner>
          <div className="loadingspinner-text">{props.spinnerText}</div>
        </Modal.Body>
      </Modal>
    );
  }
  return (
    <Modal show={props.show} centered className="loadingspinner">
      <Modal.Body className="text-center">
        <Spinner animation={props.animation} variant={props.variant} role={props.role}></Spinner>
      </Modal.Body>
    </Modal>
  );
};

export const SuccessDialog = (props: any) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={props.centered}
      show={props.show}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <AppName />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#d1e7dd", color: "#0f5132" }}>
        <div dangerouslySetInnerHTML={{__html: props.message}} ></div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
};