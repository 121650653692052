import { AppConfigConstant } from "../AppConfig";
import { AIMFloatingLabelSelect } from "../Controls/AIMSelect";
import { GetStorageItem, SetStorage } from "../Helpers/Authentication";
import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import { Modal, Button, Alert } from "react-bootstrap";
import AppName from "../Header/AppName";
import { useState } from "react";
import { Blank } from "../Constants";
import { Errors } from "../Models/Program";
import { ActionTypes } from "../Redux/Constants/action-types";
import { ClearStorage } from "../Helpers/Authentication";

const Instances = () => {
  const [errors] = useState<Errors>({ user_instance: Blank });
  const dispatch = useDispatch();
  const availableInstances = JSON.parse(GetStorageItem(AppConfigConstant.AVAI_INSTANCES));
  let userSelectedSavedInstance = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.selectedInstance);
  const showInstanceSelector = useSelector((state: RootStateOrAny) => state.userData.userLoginInfo.showInstanceSelector);
  let getSavedInstance: string = Blank;
  const hideInstanceSelector = { type: ActionTypes.SET_SHOW_INSTANCE_SELECTOR, payload: false };

  if (!userSelectedSavedInstance) {
    getSavedInstance = GetStorageItem(AppConfigConstant.USER_INSTANCE);
  };

  if (getSavedInstance) userSelectedSavedInstance = getSavedInstance;

  const [selectedInstance, setSelectedInstance] = useState(userSelectedSavedInstance);
  const setUserSelectedInstance = (event: any) => {
    setSelectedInstance(event.target.value);
  };

  const saveInstance = () => {
    if (selectedInstance) {
      SetStorage(AppConfigConstant.USER_INSTANCE, selectedInstance);
      dispatch(hideInstanceSelector);
      if (userSelectedSavedInstance !== selectedInstance) window.location.reload();
    }
  };

  const handleClose = () => {
    dispatch(hideInstanceSelector);
    setSelectedInstance(GetStorageItem(AppConfigConstant.USER_INSTANCE));
  };

  const logOut = () => {
    ClearStorage();
    window.location.href = AppConfigConstant.LOGOUT_URL;
  };

  return (
    <Modal show={showInstanceSelector}>
      <Modal.Header>
        <Modal.Title><AppName /></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AIMFloatingLabelSelect className="mb-3" name="user_instance" label="Application Instance" dataItems={availableInstances} onChange={(event: any) => setUserSelectedInstance(event)} selectedValue={selectedInstance} blankOption={false} errors={errors} />
        <Alert variant="danger">
          <Alert.Heading>Application Instance</Alert.Heading>
          <p>Select application instance and <strong>click on save button</strong></p>
          <p>Once you click save button, your application <strong>will be reloaded**</strong></p>
        </Alert>
        <p style={{ color: "#842029", fontWeight: "bold", fontSize: "17px" }}>**WARNING: Your unsaved data will be lost</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" type="button" onClick={logOut} hidden={availableInstances?.length !== 0}>Logout</Button>
        <Button variant="success" type="button" onClick={handleClose} hidden={availableInstances?.length === 0}>Cancel</Button>
        <Button variant="primary" onClick={saveInstance} disabled={availableInstances?.length === 0}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Instances;
